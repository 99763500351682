<template>
  <div class="container">
    <div class="top_container">
      <a-icon @click="$router.go(-1)" class="back_btn" type="left"/>
      <span>报障报修</span>
    </div>
    <a-form-model ref="form" :model="form" :rules="rules">
      <div class="form_container">
        <a-form-model-item label="产品编号">
          {{ single.code }}
        </a-form-model-item>
        <a-form-model-item label="产品名称">
          {{ goods.name }}
        </a-form-model-item>
        <a-form-model-item v-for="(item) in type_list" :key="item.id" style="display:none">
          <input type="radio" name="sex" v-model="item.id" checked />{{item.name}}
        </a-form-model-item>
        <a-form-model-item label="故障类型" prop="fault_list">
          <a-cascader :fieldNames="{label: 'label', value: 'value', children: 'children'}" :options="fault_list"
                      placeholder="" v-model="form.fault_list"/>
        </a-form-model-item>
        <a-form-model-item label="故障描述" prop="content">
          <a-textarea
              v-model="form.content"
              :auto-size="{ minRows: 4, maxRows: 4 }"
          />
        </a-form-model-item>
      </div>
      <div class="mt10 button-view" v-if="reportFaultCanSelectLinkman">
        <div class="form-button" @click="selectLinkman" >
          选择已有联系人<van-icon style="color: #fff;" name="arrow" />
        </div>
      </div>
      <div class="form_container mt10">
        <a-form-model-item label="联系人" prop="linkman_name">
          <a-input v-model="form.linkman_name"/>
        </a-form-model-item>
        <a-form-model-item label="联系人电话" prop="linkman_mobile">
          <a-input v-model="form.linkman_mobile"/>
        </a-form-model-item>
      </div>
      <div class="mt10 button-view" v-if="reportFaultCanSelectLinkman">
        <div class="form-button" @click="selectAddress" >
          选择已有地址<van-icon style="color: #fff;" name="arrow" />
        </div>
      </div>
      <div class="form_container mt10">
        <a-form-model-item label="地址" prop="area">
          <a-cascader :fieldNames="{label: 'name', value: 'code', children: 'children'}" :options="region"
                      placeholder="" v-model="form.area"/>
        </a-form-model-item>
        <a-form-model-item label="详细地址" prop="address">
          <a-input v-model="form.address" :maxLength="70"/>
        </a-form-model-item>
      </div>
      <div class="form_container mt10">
        <a-form-model-item label="附件" prop="attachment_list">
          <attachment-upload v-model="form.attachment_list"/>
        </a-form-model-item>
        <extend-field-form :field-list="fieldList" :form="form"></extend-field-form>
      </div>
    </a-form-model>
    <div style="padding: 20px;text-align: center">
      <a-button type="primary" :loading="loading" :disabled="loading" @click="onSubmit">提交</a-button>
    </div>
    <popup-picker ref="linkman_picker" :columns="linkmanList" value-key="showName" @confirm="linkmanConfirm"></popup-picker>
    <popup-picker ref="address_picker" :columns="addressList" value-key="full_address" @confirm="addressConfirm"></popup-picker>
  </div>
</template>
<script>
import AttachmentUpload from '@/components/Attachment/upload'
import ExtendFieldForm from '@/components/ExtendField/form'
import PopupPicker from '@/components/PopupPicker'
import request from '@/utils/request';
import enums from "../utils/enums";
import cookies from 'vue-cookies';
import request_get from '@/utils/request_get';
//import { sync } from 'glob';
//import { SlowBuffer } from 'buffer';
var handler_id=0
export default {
  components: {ExtendFieldForm, AttachmentUpload, PopupPicker},
  data() {
    return {
      enums,
      form: {},
      rules: {
        linkman_name: [{required: true, message: '请输入联系人'}],
        linkman_mobile: [
          {required: true, message: '请输入联系人电话'},
        ],
        content: [
          {required: true, message: '请输入故障描述'},
          {max: 255, message: '长度超出限制'}
        ],
        fault_list: [{
          type: 'array',
          required: true,
          message: '请故障类别',
          trigger: 'change'
        }]
      },
      region: [],
      fault_list: [],
      fieldList: [],
      goods: {},
      single: {},
      loading: false,
      linkmanList: [],
      addressList: [],
      reportFaultCanSelectLinkman: false,
      type_list:0
    }
  },
  created: function () {
    this.form.customer_id = this.$route.query.customerId
    this.form.enterprise_id = this.$route.query.enterpriseId
    this.form.single_id = this.$route.query.singleProductId
    const qrId = this.$route.query.qrId
    request({
      url: 'single/detail_by_qr_id',
      data: {qr_id: qrId}
    }).then(r => {
      const { data } = r.data
      this.single = data.single
      this.goods = data.goods
      this.fieldList = data.fieldList
      this.reportFaultCanSelectLinkman = data.report_fault_can_select_linkman
      this.linkmanList = data.linkman_list.map((item) => {
        item.showName = `${item.name}(${item.mobile ? item.mobile : '无'})`
        return item
      })
      this.addressList = data.address_list
      const linkman = data.linkman_list.find(item => item.is_default === 1)
      if (this.reportFaultCanSelectLinkman && linkman) {
        this.$set(this.form, 'linkman_name', linkman.name)
        this.$set(this.form, 'linkman_mobile', linkman.mobile)
      }
      const address = data.address_list.find(item => item.is_default === 1)
      if (this.reportFaultCanSelectLinkman && address) {
        const area = []
        if (address.province_code) {
          area.push(address.province_code)
        }
        if (address.city_code) {
          area.push(address.city_code)
        }
        if (address.area_code) {
          area.push(address.area_code)
        }
        this.$set(this.form, 'area', area)
        this.$set(this.form, 'address', address.address)
      }
    })
    request({
      url: 'region/tree'
    }).then(r => {
      this.region = r.data.data
    })
    request({
      url: 'dropdown/category_cascade',
      data: {busi_type: 4, enterprise_id: this.form.enterprise_id}
    }).then(r => {
      this.fault_list = r.data.data
      
    })
    request({
      url: 'dropdown/work_order_type',
      data:{token:cookies.get('token'),enterprise_id: this.form.enterprise_id,type: 1}
    }).then(r => {
      this.type_list = r.data.data
    })

request({
      url: 'user/info',
      data:{token:cookies.get('token'),enterprise_id: this.form.enterprise_id,type: 1}
    }).then(r => {
      cookies.set('location',r.data.data.location,60*60)
      console.log(r.data.data)
    })

    var dataArr=[];
    request({
      url: 'user/user_list_daqu',
      data:{identity:1,daqu:cookies.get('daqu')}
    }).then(r => {
      console.log(r.data)
      dataArr=r.data.data;
        var location=[]
        location=cookies.get('location').split(",")
          var index=0
          var location_a=[]
          var location_b=[]
          var d0=[];
          for ( index = 0; index < dataArr.length; index++) {
            if(dataArr[index][3]==0){
                d0.push(dataArr[index]);
            }
          }
          if(d0!=undefined){
            var d01=d0[0];
            for (index = 1; index < d0.length; index++) {
              location_a=d01[2].split(',')
              location_b=d0[index][2].split(",")
              d01.distance=Math.abs(Math.sqrt((location_a[0]-location[0])*(location_a[0]-location[0])+(location_a[1]-location[1])*(location_a[1]-location[1])))
              d0[index].distance=Math.abs(Math.sqrt((location_b[0]-location[0])*(location_b[0]-location[0])+(location_b[1]-location[1])*(location_b[1]-location[1])))
              if(d01.distance>d0[index].distance){
                d01=d0[index];
              }
            }
          }

          var d1=[];
          for ( index = 0; index < dataArr.length; index++) {
            if(dataArr[index][3]==1){
                d1.push(dataArr[index]);
            }
          }
          if(d1!=undefined){
            var d11=d1[0];
            for (index = 1; index < d1.length; index++) {
              location_a=d11[2].split(',')
              location_b=d1[index][2].split(",")
              d11.distance=Math.abs(Math.sqrt((location_a[0]-location[0])*(location_a[0]-location[0])+(location_a[1]-location[1])*(location_a[1]-location[1])))
              d1[index].distance=Math.abs(Math.sqrt((location_b[0]-location[0])*(location_b[0]-location[0])+(location_b[1]-location[1])*(location_b[1]-location[1])))
              if(d11.distance>d1[index].distance){
                d11=d1[index];
              }
            }
          }

          var d2=[];
          for ( index = 0; index < dataArr.length; index++) {
            if(dataArr[index][3]==2){
                d2.push(dataArr[index]);
            }
          }
          if(d2!=undefined){
            var d21=d2[0];
            for (index = 1; index < d2.length; index++) {
              location_a=d21[2].split(',')
              location_b=d2[index][2].split(",")
              d21.distance=Math.abs(Math.sqrt((location_a[0]-location[0])*(location_a[0]-location[0])+(location_a[1]-location[1])*(location_a[1]-location[1])))
              d2[index].distance=Math.abs(Math.sqrt((location_b[0]-location[0])*(location_b[0]-location[0])+(location_b[1]-location[1])*(location_b[1]-location[1])))
              if(d21.distance>d2[index].distance){
                d21=d2[index];
              }
            }
          }

          var d3=[];
          for ( index = 0; index < dataArr.length; index++) {
            if(dataArr[index][3]==3){
                d3.push(dataArr[index]);
            }
          }
          if(d3!=undefined){
            var d31=d3[0];
            for (index = 1; index < d3.length; index++) {
              location_a=d31[2].split(',')
              location_b=d3[index][2].split(",")
              d31.distance=Math.abs(Math.sqrt((location_a[0]-location[0])*(location_a[0]-location[0])+(location_a[1]-location[1])*(location_a[1]-location[1])))
              d3[index].distance=Math.abs(Math.sqrt((location_b[0]-location[0])*(location_b[0]-location[0])+(location_b[1]-location[1])*(location_b[1]-location[1])))
              if(d31.distance>d3[index].distance){
                d31=d3[index];
              }
            }
          }

          var data_count=0
          setTimeout(function(){ 
            if(d01!=undefined){
              data_count++
              request_get({
                        url: 'https://restapi.amap.com/v3/distance?origins='+d01[2]+'&destination='+location+'&output=json&key=1add72417f7ac9301a98b038771c68d7&type=1'
                      }).then(r => {
                        d01.distance=r.data.results[0].distance
                        console.log(r.data)
                        data_count--
                      })
            }
          }, 100);
          setTimeout(function(){ 
            if(d11!=undefined){
              data_count++
              request_get({
                        url: 'https://restapi.amap.com/v3/distance?origins='+d11[2]+'&destination='+location+'&output=json&key=1add72417f7ac9301a98b038771c68d7&type=1'
                      }).then(r => {
                        d11.distance=r.data.results[0].distance
                        console.log(r.data)
                        data_count--
                      }) 
            }
          }, 200);
          setTimeout(function(){ 
            if(d21!=undefined){
              data_count++
              request_get({
                        url: 'https://restapi.amap.com/v3/distance?origins='+d21[2]+'&destination='+location+'&output=json&key=1add72417f7ac9301a98b038771c68d7&type=1'
                      }).then(r => {
                        d21.distance=r.data.results[0].distance
                        console.log(r.data)
                        data_count--
                      })
            }
          }, 300);
          setTimeout(function(){ 
            if(d31!=undefined){
              data_count++
              request_get({
                        url: 'https://restapi.amap.com/v3/distance?origins='+d31[2]+'&destination='+location+'&output=json&key=1add72417f7ac9301a98b038771c68d7&type=1'
                      }).then(r => {
                        d31.distance=r.data.results[0].distance
                        console.log(r.data)
                        data_count--
                      })
            }
          }, 400);

          setTimeout(function(){ 
              if(data_count==0){
                var base=200000;
                if(d01!=undefined && d11!=undefined){
                  if((d01.distance-d11.distance)<=base){
                      handler_id=d01[0]
                  }else{
                      handler_id=d11[0]
                  }
                }else if(d01!=undefined){
                    handler_id=d01[0]
                }else if(d11!=undefined){
                    handler_id=d11[0]
                }

                if(d01==undefined){
                  if(d11!=undefined && d21!=undefined){
                    if((d11.distance-d21.distance)<=base){
                        handler_id=d11[0]
                    }else{
                        handler_id=d21[0]
                    }
                  }else if(d11!=undefined){
                      handler_id=d11[0]
                  }else if(d21!=undefined){
                      handler_id=d21[0]
                  }
                }
                
                if(d01==undefined && d11==undefined){
                  if(d21!=undefined && d31!=undefined){
                    if((d21.distance-d31.distance)<=base){
                        handler_id=d21[0]
                    }else{
                        handler_id=d31[0]
                    }
                  }else if(d21!=undefined){
                      handler_id=d21[0]
                  }else if(d31!=undefined){
                      handler_id=d31[0]
                  }
                }

                    console.log(d01)
                    console.log(d11)
                    console.log(d21)
                    console.log(d31)
                    console.log(handler_id)
                    console.log(location)
              }
           }, 2000);
          

    })
  },
  methods: {
    linkmanConfirm (value) {
      const linkman = this.linkmanList.find(item => item.id === value)
      if (linkman) {
        this.$set(this.form,'linkman_name', linkman.name)
        this.$set(this.form,'linkman_mobile', linkman.mobile)
      }
    },
    addressConfirm (value) {
      const address = this.addressList.find(item => item.id === value)
      if (address) {
        const area = []
        if (address.province_code) {
          area.push(address.province_code)
        }
        if (address.city_code) {
          area.push(address.city_code)
        }
        if (address.area_code) {
          area.push(address.area_code)
        }
        this.$set(this.form, 'area', area)
        this.$set(this.form, 'address', address.address)
      }
    },
    selectLinkman () {
      this.$refs.linkman_picker.onOpen()
    },
    selectAddress () {
      this.$refs.address_picker.onOpen()
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form))
          data.attachment_list = this.form.attachment_list
          if (data.area) {
            if (data.area[0]) {
              data.province_code = data.area[0]
            }
            if (data.area[1]) {
              data.city_code = data.area[1]
            }
            if (data.area[2]) {
              data.area_code = data.area[2]
            }
          }
          if (data.fault) {
            if (data.fault[0]) {
              data.big_fault_id = data.fault[0]
            }
            if (data.fault[1]) {
              data.small_fault_id = data.fault[1]
            }
          }
          var f=data.fault_list
          data.fault_list=[f]
          this.loading = true
          request({
            url: 'feedback/customer_add',
            data: data
          }).then(r => {
              if (r.data.success) {
                data.goods_id=r.data.data.goods_id
                data.handler_id=handler_id
                data.feedback_id=r.data.data.id
                data.token=cookies.get('token')
                data.in_pool=2
                data.type_id=807
                request({
                  url: 'work_order/add_my',
                  data: data
                }).then(r => {
                  if (r.data.success) {
                    console.log(r.data)
                  } else if (r.data.error_code === enums.STATE.FEEDBACK_REPORT_FAULT_MOBILE.v) {
                    console.log(r.data.msg)
                  } else {
                    console.log('网络异常,请稍后重试')
                  }
                })
              alert('您的报修申请已经提交给客服人员，稍后会跟您联系，请保持电话畅通')
              this.$router.go(-1)
            } else if (r.data.error_code === enums.STATE.FEEDBACK_REPORT_FAULT_MOBILE.v) {
              alert(r.data.msg)
            } else {
              alert('网络异常,请稍后重试')
            }
          }).finally(() => {
            this.loading = false
          })
        }
      });
    }
  }
}


</script>
<style lang="less" scoped>
@import "../assets/css/form.less";

.ant-form-item {
  align-items: center;
}

/deep/ .ant-form-item-label {
  label::before {
    display: inline-block;
    margin-right: 11px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '';
  }
  .ant-form-item-required::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.container {
  padding: 8px;
  padding-top: 58px;

  .form_container {
    background-color: #fff;
    border-radius: 10px;
    .ant-form-item:last-child {
      border-bottom: none;
    }
  }

  .mt10 {
    margin-top: 10px;
  }

  .button-view {
    padding: 0 8px;
    display: flex;
    justify-content: flex-end;
  }

  .form-button {
    display: flex;
    align-items: center;
    margin-left: 5px;
    background: #3573e0;
    color: #fff;
    border-radius: 3px;
    padding: 2px 5px;
    font-size: 14px;
  }
}
</style>
